// import { createSelector } from '@reduxjs/toolkit';

import { InitialStateTypes } from '~/store';

//
// Dumb selectors
//

export const selectUiMenuOpen = (state: InitialStateTypes) => state.ui.menuOpen;
export const selectUiModal = (state: InitialStateTypes) => state.ui.modal;
export const selectUiDrawer = (state: InitialStateTypes) => state.ui.drawer;
export const selectUiDropdown = (state: InitialStateTypes) => state.ui.dropdown;
export const selectUiSidebar = (state: InitialStateTypes) => state.ui.sidebar;
export const selectUiSearch = (state: InitialStateTypes) => state.ui.search;
export const selectUiReader = (state: InitialStateTypes) => state.ui.reader;

//
// Memoized selectors
//

// export const selectActiveBook = createSelector([selectActiveBookKey, selectBooks], (activeBookKey, books) => {
//     if (activeBookKey === '__none__' || !books[activeBookKey]) {
//         return null;
//     }

//     return books[activeBookKey];
// });
