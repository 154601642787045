import { Box, ButtonBase, useTheme } from '@mui/material';

import { hexToRGBA } from '~/utils/colors';
import { AccessibilitySkipButtonProps } from './types';

const AccessibilitySkipButton = ({ label, to, sx }: AccessibilitySkipButtonProps) => {
    const theme = useTheme();
    return (
        <ButtonBase
            component="a"
            href={to ? to : '#main'}
            sx={[
                {
                    maxHeight: '0px',
                    overflow: 'hidden',
                    backgroundColor: theme.palette.background.paper,
                    display: 'block',
                    textAlign: 'center',
                    outline: 'none',
                    transition: 'max-height 250ms',
                    color: theme.palette.secondary.main,
                    '&.Mui-focusVisible': {
                        maxHeight: '100px',
                    },
                    '&.Mui-focusVisible span': {
                        boxShadow: theme.shadows[24],
                    },
                    '&:hover span, &.Mui-hoverVisible span': {
                        borderBottom: `2px solid ${theme.palette.secondary.main}`,
                    },
                },
                { ...sx },
            ]}
        >
            <Box
                component="span"
                sx={{
                    display: 'inline-block',
                    my: 4,
                    transition: 'border-color 250ms',
                    color: theme.palette.secondary.main,
                    borderBottom: `2px solid ${hexToRGBA(theme.palette.secondary.main, 0.3)}`,
                }}
            >
                {label ? label : 'Hopp til hovedinnhold'}
            </Box>
        </ButtonBase>
    );
};

export default AccessibilitySkipButton;
